import { FormInterface } from '../interface';

//handles add panel functionality
export const addPanel = (data: FormInterface) => ({
  type: 'ADD_PANEL',
  parameters: data.parameters,
  contentArr: data.contentArr,
  num: data.num,
});

//handles title value within the panel
export const handleChange = (data: FormInterface) => ({
  type: 'HANDLE_CHANGE',
  parameters: data.parameters,
  errors: data.errors,
});

export const rearangeArray = (data: FormInterface) => ({
  type: 'REARANGE_ARRAY',
  parameters: data.parameters,
  contentArr: data.contentArr,
});

//handles the value of main-title of the form
export const handleTitle = (data: FormInterface) => ({
  type: 'HANDLE_TITLE',
  title: data.title,
});

//handles the remove functionality for selected panel
export const removeClick = (data: FormInterface) => ({
  type: 'REMOVE_CLICK',
  parameters: data.parameters,
  contentArr: data.contentArr,
  errors: data.errors,
});

//validation for empty feildssta
export const errorsFetch = (data: FormInterface) => ({
  type: 'ERRORS',
  errors: data.errors,
});

export const makeEdit = (data: FormInterface) => ({
  type: 'MAKE_EDIT',
  title: data.title,
  noOfLayout: data.noOfLayout,
  fixedLayout: data.fixedLayout,
  parameters: data.parameters,
  contentArr: data.contentArr,
  component: data.type,
  fontHeadings: data.fontHeadings,
  fontFamily: data.fontFamily,
  fontSize: data.fontSize,
  fontWeight: data.fontWeight,
  radius: data.radius,
  bgcolor: data.bgcolor,
  icon: data.icon,
  alignment: data.alignment,
  fontColor: data.fontColor,
  inactivefontColor: data.inactivefontColor,
  borderType: data.borderType,
  borderColor: data.borderColor,
  borderSize: data.borderSize,
  borderStyle: data.borderStyle,
  setcaptionAlignment: data.setcaptionAlignment,
  iconColor: data.iconColor,
  iconSize: data.iconSize,
  iconAlignment: data.iconAlignment,
  iconType: data.iconType,
  imageBgColor: data.imageBgColor,
  dndLayout: data.dndLayout,
  dndParameters: data.dndParameters,
  dndArray: data.dndArray,
  dragAreaType: data.dragAreaType,
  paddingTop: data.paddingTop,
  paddingLeft: data.paddingLeft,
  paddingBottom: data.paddingBottom,
  paddingRight: data.paddingRight,
  dropzoneBgColor: data.dropZoneBgColor,
  dropzoneOpacity: data.dropZoneOpacity,
  hotspotIcon: data.hotspotIcon,
  btnPaddingTop: data.btnPaddingTop,
  btnPaddingLeft: data.btnPaddingLeft,
  btnPaddingBottom: data.btnPaddingBottom,
  btnPaddingRight: data.btnPaddingRight,
  btnFontColor: data.btnFontColor,
  btnFontWeight: data.btnFontWeight,
  btnFontSize: data.btnFontSize,
  btnFontFamily: data.btnFontFamily,
  btnBgColor: data.btnBgColor,
  btnType: data.btnType,
  borderTop: data.borderTop,
  borderRight: data.borderRight,
  borderLeft: data.borderLeft,
  borderBottom: data.borderBottom,
  marginTop: data.marginTop,
  marginBottom: data.marginBottom,
  borderRadius: data.borderRadius,
  clickBorderRadius: data.clickBorderRadius,
  clickBorderTop: data.clickBorderTop,
  clickBorderRight: data.clickBorderRight,
  clickBorderLeft: data.clickBorderLeft,
  clickBorderBottom: data.clickBorderBottom,
  clickBorderColor: data.clickBorderColor,
  clickBorderType: data.clickBorderType,
  clickBgColor: data.clickBgColor,
  revealBorderRadius: data.revealBorderRadius,
  revealBorderTop: data.revealBorderTop,
  revealBorderRight: data.revealBorderRight,
  revealBorderLeft: data.revealBorderLeft,
  revealBorderBottom: data.revealBorderBottom,
  revealBorderColor: data.revealBorderColor,
  revealBorderType: data.revealBorderType,
  revealBgColor: data.revealBgColor,
  clickPaddingTop: data.clickPaddingTop,
  clickPaddingRight: data.clickPaddingRight,
  clickPaddingLeft: data.clickPaddingLeft,
  clickPaddingBottom: data.clickPaddingBottom,
  revealPaddingTop: data.revealPaddingTop,
  revealPaddingRight: data.revealPaddingRight,
  revealPaddingLeft: data.revealPaddingLeft,
  revealPaddingBottom: data.revealPaddingBottom,
  displayActiveColorPicker: data.displayActiveColorPicker,
  separatorColor: data.separatorColor,
  separatorWidth: data.separatorWidth,
});

export const setType = (data: FormInterface) => ({
  type: 'SET_TYPE',
  component: data.type,
});

//To set the ID for the panels
export const setValue = (data: FormInterface) => ({
  type: 'SET_VALUE',
  num: data.num,
  option_id: data.option_id,
});

// fetching the values for customization
export const fontHeadings = (data: FormInterface) => ({
  type: 'FONT_HEADINGS',
  component: data.fontHeadings,
});

export const fontType = (data: FormInterface) => ({
  type: 'FONT_TYPE',
  component: data.fontFamily,
});

export const fontSize = (data: FormInterface) => ({
  type: 'FONT_SIZE',
  component: data.fontSize,
});

export const borderType = (data: FormInterface) => ({
  type: 'CUSTOM_BORDER',
  borderType: data.borderType,
});

export const setBorderColor = (data: FormInterface) => ({
  type: 'BORDER_COLOR',
  borderColor: data.borderColor,
});

export const setBorderSize = (data: FormInterface) => ({
  type: 'BORDER_SIZE',
  borderSize: data.borderSize,
});

export const setBorderStyle = (data: FormInterface) => ({
  type: 'BORDER_STYLE',
  borderStyle: data.borderStyle,
});

export const fontWeight = (data: FormInterface) => ({
  type: 'FONT_WEIGHT',
  fontWeight: data.fontWeight,
});
export const setIcon = (data: FormInterface) => ({
  type: 'SET_ICON',
  icon: data.icon,
});
export const color1 = (data: FormInterface) => ({
  type: 'CUSTOM_COLOR',
  color: data.color,
});
export const setBgColor = (data: FormInterface) => ({
  type: 'CUSTOM_BGCOLOR',
  bgcolor: data.bgcolor,
});

export const radius = (data: FormInterface) => ({
  type: 'CUSTOM_RADIUS',
  radius: data.radius,
});
export const setAlignment = (data: FormInterface) => ({
  type: 'CUSTOM_ALIGNMENT',
  alignment: data.alignment,
});

export const fontColor = (data: FormInterface) => ({
  type: 'CUSTOM_FONTCOLOR',
  fontColor: data.fontColor,
});

export const inactivefontColor = (data: FormInterface) => ({
  type: 'CUSTOM_INACTIVEFONTCOLOR',
  inactivefontColor: data.inactivefontColor,
});

export const setcaptionAlignment = (data: FormInterface) => ({
  type: 'CUSTOM_CAPTIONALIGNMENT',
  setcaptionAlignment: data.setcaptionAlignment,
});

export const setIconAlignment = (data: FormInterface) => ({
  type: 'CUSTOM_ICON_ALIGNMENT',
  iconAlignment: data.iconAlignment,
});

export const setIconColor = (data: FormInterface) => ({
  type: 'ICON_COLOR',
  iconColor: data.iconColor,
});

export const setIconSize = (data: FormInterface) => ({
  type: 'ICON_SIZE',
  iconSize: data.iconSize,
});

export const setIconType = (data: FormInterface) => ({
  type: 'ICON_TYPE',
  iconType: data.iconType,
});

export const setImageBgColor = (data: FormInterface) => ({
  type: 'IMAGE_BG_COLOR',
  imageBgColor: data.imageBgColor,
});

export const setDragandDropLayout = (data: FormInterface) => ({
  type: 'DRAG_AND_DROP_LAYOUT',
  dndLayout: data.dndLayout,
  errors: {},
});

export const setParameter = (data: FormInterface) => ({
  type: 'SET_BACKGROUND_IMG',
  dndParameters: data.dndParameters,
  errors: data.errors,
});

export const deleteImage = (data: FormInterface) => ({
  type: 'REMOVE_IMAGE',
  removeImage: data.removeImage,
});

export const handleErrors = (data: FormInterface) => ({
  type: 'SET_ERROR',
  errors: data.errors,
});

//for draganddrop
export const addSection = (data: FormInterface) => ({
  type: 'ADD_SECTION',
  dndParameters: data.parameters,
  dndArray: data.contentArr,
  num: data.num,
});

export const sortArray = (data: FormInterface) => ({
  type: 'SORT_ARRAY',
  dndParameters: data.dndParameters,
  dndArray: data.dndArray,
});

export const removeElement = (data: FormInterface) => ({
  type: 'REMOVE_ELEMENT',
  dndParameters: data.parameters,
  dndArray: data.contentArr,
  errors: data.errors,
});

export const setDragAreaType = (data: FormInterface) => ({
  type: 'DRAG_AREA_TYPE',
  dragAreaType: data.dragAreaType,
});

export const paddingTop = (data: FormInterface) => ({
  type: 'CUSTOM_PADDINGTOP',
  paddingTop: data.paddingTop,
});

export const paddingLeft = (data: FormInterface) => ({
  type: 'CUSTOM_PADDINGLEFT',
  paddingLeft: data.paddingLeft,
});

export const paddingBottom = (data: FormInterface) => ({
  type: 'CUSTOM_PADDINGBOTTOM',
  paddingBottom: data.paddingBottom,
});

export const paddingRight = (data: FormInterface) => ({
  type: 'CUSTOM_PADDINGRIGHT',
  paddingRight: data.paddingRight,
});

export const setDropzoneBgColor = (data: FormInterface) => ({
  type: 'DROPZONE_BGCOLOR',
  dropzoneBgColor: data.dropzoneBgColor,
});

export const setDropzoneOpacity = (data: FormInterface) => ({
  type: 'DROPZONE_OPACITY',
  dropzoneOpacity: data.dropzoneOpacity,
});

//for hotspot
export const setHotspotIcon = (data: FormInterface) => ({
  type: 'SET_HOTSPOT_ICON',
  hotspotIcon: data.hotspotIcon,
});

export const customPaddingtop = (data: FormInterface) => ({
  type: 'PADDINGTOP',
  btnPaddingTop: data.btnPaddingTop,
});

export const customPaddingleft = (data: FormInterface) => ({
  type: 'PADDINGLEFT',
  btnPaddingLeft: data.btnPaddingLeft,
});

export const customPaddingbottom = (data: FormInterface) => ({
  type: 'PADDINGBOTTOM',
  btnPaddingBottom: data.btnPaddingBottom,
});

export const customPaddingright = (data: FormInterface) => ({
  type: 'PADDINGRIGHT',
  btnPaddingRight: data.btnPaddingRight,
});

export const customFontFamily = (data: FormInterface) => ({
  type: 'FONTFAMILY',
  btnFontFamily: data.btnFontFamily,
});

export const customFontSize = (data: FormInterface) => ({
  type: 'FONTSIZE',
  btnFontSize: data.btnFontSize,
});

export const customFontColor = (data: FormInterface) => ({
  type: 'FONTCOLOR',
  btnFontColor: data.btnFontColor,
});

export const customFontWeight = (data: FormInterface) => ({
  type: 'FONTWEIGHT',
  btnFontWeight: data.btnFontWeight,
});
export const noOfLayout = (data: FormInterface) => ({
  type: 'NO_OF_LAYOUT',
  noOfLayout: data.noOfLayout,
});
export const fixedLayout = (data: FormInterface) => ({
  type: 'FIXED_LAYOUT',
  fixedLayout: data.fixedLayout,
});
export const customBgColor = (data: FormInterface) => ({
  type: 'BG_COLOR',
  btnBgColor: data.btnBgColor,
});
export const setdisplayInActiveColorPicker = (data: FormInterface) => ({
  type: 'ACTIVE_COLOR_TAB',
  displayActiveColorPicker: data.displayActiveColorPicker,
});
export const setSeparatorColor = (data: FormInterface) => ({
  type: 'SEPARATOR_COLOR',
  separatorColor: data.separatorColor,
});
export const setSeparatorWidth = (data: FormInterface) => ({
  type: 'SEPARATOR_WIDTH',
  separatorWidth: data.separatorWidth,
});
export const customBtnType = (data: FormInterface) => ({
  type: 'BUTTON_TYPE',
  btnType: data.btnType,
});

export const setDefault = () => ({
  type: 'RESET',
});

export const setBorderTop = (data: FormInterface) => ({
  type: 'BORDER_TOP',
  borderTop: data.borderTop,
});

export const setBorderRight = (data: FormInterface) => ({
  type: 'BORDER_RIGHT',
  borderRight: data.borderRight,
});

export const setBorderLeft = (data: FormInterface) => ({
  type: 'BORDER_LEFT',
  borderLeft: data.borderLeft,
});

export const setBorderBottom = (data: FormInterface) => ({
  type: 'BORDER_BOTTOM',
  borderBottom: data.borderBottom,
});

export const setBorderRadius = (data: FormInterface) => ({
  type: 'BORDER_RADIUS',
  borderRadius: data.borderRadius,
});

export const setMarginTop = (data: FormInterface) => ({
  type: 'MARGIN_TOP',
  marginTop: data.marginTop,
});

export const setMarginBottom = (data: FormInterface) => ({
  type: 'MARGIN_BOTTOM',
  marginBottom: data.marginBottom,
});

export const setClickBorderTop = (data: FormInterface) => ({
  type: 'CLICK_BORDER_TOP',
  clickBorderTop: data.clickBorderTop,
});

export const setClickBorderRight = (data: FormInterface) => ({
  type: 'CLICK_BORDER_RIGHT',
  clickBorderRight: data.clickBorderRight,
});

export const setClickBorderLeft = (data: FormInterface) => ({
  type: 'CLICK_BORDER_LEFT',
  clickBorderLeft: data.clickBorderLeft,
});

export const setClickBorderBottom = (data: FormInterface) => ({
  type: 'CLICK_BORDER_BOTTOM',
  clickBorderBottom: data.clickBorderBottom,
});

export const setClickBorderRadius = (data: FormInterface) => ({
  type: 'CLICK_BORDER_RADIUS',
  clickBorderRadius: data.clickBorderRadius,
});

export const setClickBorderType = (data: FormInterface) => ({
  type: 'CLICK_BORDER_TYPE',
  clickBorderType: data.clickBorderType,
});

export const setClickBorderColor = (data: FormInterface) => ({
  type: 'CLICK_BORDER_COLOR',
  clickBorderColor: data.clickBorderColor,
});

export const setClickBgColor = (data: FormInterface) => ({
  type: 'CLICK_BG_COLOR',
  clickBgColor: data.clickBgColor,
});

export const setRevealBorderTop = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_TOP',
  revealBorderTop: data.revealBorderTop,
});

export const setRevealBorderRight = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_RIGHT',
  revealBorderRight: data.revealBorderRight,
});

export const setRevealBorderLeft = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_LEFT',
  revealBorderLeft: data.revealBorderLeft,
});

export const setRevealBorderBottom = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_BOTTOM',
  revealBorderBottom: data.revealBorderBottom,
});

export const setRevealBorderRadius = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_RADIUS',
  revealBorderRadius: data.revealBorderRadius,
});

export const setRevealBorderType = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_TYPE',
  revealBorderType: data.revealBorderType,
});

export const setRevealBorderColor = (data: FormInterface) => ({
  type: 'REVEAL_BORDER_COLOR',
  revealBorderColor: data.revealBorderColor,
});

export const setRevealBgColor = (data: FormInterface) => ({
  type: 'REVEAL_BG_COLOR',
  revealBgColor: data.revealBgColor,
});

export const setClickPaddingTop = (data: FormInterface) => ({
  type: 'CLICK_PADDING_TOP',
  clickPaddingTop: data.clickPaddingTop,
});

export const setClickPaddingRight = (data: FormInterface) => ({
  type: 'CLICK_PADDING_RIGHT',
  clickPaddingRight: data.clickPaddingRight,
});

export const setClickPaddingLeft = (data: FormInterface) => ({
  type: 'CLICK_PADDING_LEFT',
  clickPaddingLeft: data.clickPaddingLeft,
});

export const setClickPaddingBottom = (data: FormInterface) => ({
  type: 'CLICK_PADDING_BOTTOM',
  clickPaddingBottom: data.clickPaddingBottom,
});

export const setRevealPaddingTop = (data: FormInterface) => ({
  type: 'REVEAL_PADDING_TOP',
  revealPaddingTop: data.revealPaddingTop,
});

export const setRevealPaddingRight = (data: FormInterface) => ({
  type: 'REVEAL_PADDING_RIGHT',
  revealPaddingRight: data.revealPaddingRight,
});

export const setRevealPaddingLeft = (data: FormInterface) => ({
  type: 'REVEAL_PADDING_LEFT',
  revealPaddingLeft: data.revealPaddingLeft,
});

export const setRevealPaddingBottom = (data: FormInterface) => ({
  type: 'REVEAL_PADDING_BOTTOM',
  revealPaddingBottom: data.revealPaddingBottom,
});
