/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import ProgressBar from './progressBar';
import { getBorderStyle } from '../../../middleware/getStyle';
import ReactHtmlParser from 'react-html-parser';
import McqTipComponent from './mytips';
import { shuffleArrayValues } from '../../../middleware/utils';
import { Button, Form } from 'react-bootstrap';
import ShowScoreComponent from '../../layouts/scoreProgressBar/showScoreLayout';
import { hoverOff, hoverOn } from '../draganddrop/dndEvents';

const MCQComponent = (props: any) => {
  const { parameters, customisations } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionsCollection, setQuestionsCollection] = useState<any>([{}]);
  const [isTryAgain, setIsTryAgain] = useState(false);
  const [checkAnswer, setCheckAnswer] = useState(true);
  const [showNext, setShowNext] = useState(false);
  const [isGetResult, setIsGetResult] = useState(false);
  const [score, setScore] = useState(0);
  const [isAnswered, setIsAnswered] = useState(true);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const mouseover = customisations.btnType === 'btn btn-outline-dark' ? true : false;
  const btnType = customisations.btnType;
  const alignment = customisations.alignment;

  // setting the default color for the older interactives
  const pbBgColor = customisations.pbBgColor ? customisations.pbBgColor : '#1D77CC';
  const pbFontColor = customisations.pbFontColor ? customisations.pbFontColor : '#ffffff';
  const bgColor = customisations.bgColor ? customisations.bgColor : '#ffffff';
  const opBgColor = customisations.opBgColor ? customisations.opBgColor : '#f8f9fa';
  const borderStyle = getBorderStyle(customisations);
  const buttonFontColor = customisations.buttonFontColor
    ? customisations.buttonFontColor
    : '#ffffff';
  const buttonBgColor = customisations.buttonBgColor ? customisations.buttonBgColor : '#1D77CC';
  const fontFamily = customisations.fontFamily ? customisations.fontFamily : 'Helvetica';
  const fontSize = customisations.fontSize ? customisations.fontSize : '16';
  const fontWeight = customisations.fontWeight ? customisations.fontWeight : 'Normal';

  const buttonstyle = {
    background: `${customisations.buttonBgColor}`,
    fontFamily: `${fontFamily}`,
    fontSize: `${fontSize}px`,
    fontWeight: fontWeight,
    color: `${buttonFontColor}`,
    border: '2px solid' + `${customisations.buttonBgColor}`,
    paddingTop: customisations.customPaddingtop ? `${customisations.customPaddingtop}px` : 10,
    paddingLeft: customisations.customPaddingleft ? `${customisations.customPaddingleft}px` : 10,
    paddingBottom: customisations.customPaddingbottom
      ? `${customisations.customPaddingbottom}px`
      : 10,
    paddingRight: customisations.customPaddingright ? `${customisations.customPaddingright}px` : 10,
  };

  const buttonUnhover = {
    background: 'white',
    color: '#000000',
    fontFamily: `${customisations.fontFamily}`,
    fontSize: `${customisations.fontSize}px`,
    fontWeight: fontWeight,
    border: '2px solid' + `${buttonBgColor}`,
    paddingTop: customisations.customPaddingtop ? `${customisations.customPaddingtop}px` : 10,
    paddingLeft: customisations.customPaddingleft ? `${customisations.customPaddingleft}px` : 10,
    paddingBottom: customisations.customPaddingbottom
      ? `${customisations.customPaddingbottom}px`
      : 10,
    paddingRight: customisations.customPaddingright ? `${customisations.customPaddingright}px` : 10,
  };

  const reset = useCallback(() => {
    const mcqCollection = JSON.parse(JSON.stringify(parameters));
    if (customisations.shuffle) {
      for (let i = 0; i < mcqCollection?.length; i++) {
        mcqCollection[i].options = shuffleArrayValues(mcqCollection[i].options);
      }
    }
    setScore(0);
    setIsTryAgain(false);
    setCurrentQuestion(0);
    setQuestionsCollection([...mcqCollection]);
    setIsAnswered(true);
    setCheckAnswer(customisations.checkAnswer);
    setShowNext(!customisations.checkAnswer && mcqCollection.length > 1);
    setIsGetResult(!customisations.checkAnswer && mcqCollection.length === 1);
  }, [customisations, parameters]);

  useEffect(() => {
    reset();
  }, [reset]);

  const getActionButtonText = () => {
    switch (true) {
      case checkAnswer:
        return 'Check Answer';
      case showNext:
        return 'Next';
      case isGetResult:
        return 'Get Results';
      case isTryAgain:
        return 'Try Again';
      default:
        return '';
    }
  };

  const getActionButtonFunction = () => {
    switch (true) {
      case checkAnswer:
        return validateAnswer();
      case showNext:
        return handleNext();
      case isGetResult:
        return getResults();
      case isTryAgain:
        return reset();
      default:
        return null;
    }
  };

  const getResults = () => {
    let scoreValue = score;
    questionsCollection.forEach((item: { answerCorrect: string }) => {
      if (item.answerCorrect === 'true') {
        ++scoreValue;
      }
    });
    setIsGetResult(false);
    setScore(parseInt(((scoreValue / questionsCollection.length) * 100).toFixed()));
    setIsTryAgain(true);
  };

  const handleNext = async () => {
    const { checkAnswer } = customisations;
    if (!checkAnswer) await validateAnswer();
    const isLast = currentQuestion + 2 === questionsCollection.length;
    setIsAnswered(true);
    setCheckAnswer(checkAnswer);
    setShowNext(!checkAnswer && !isLast);
    setIsGetResult(!checkAnswer && isLast);
    setCurrentQuestion(currentQuestion + 1);
  };

  const updateAnswer = (event: any, index: number) => {
    if (questionsCollection[currentQuestion]?.checked) {
      return;
    }
    let answerValue = event.target.value;
    setSelectedOptionIndex(index);
    let stateToUpdate = [...questionsCollection];
    stateToUpdate[currentQuestion].answerCorrect = answerValue;
    setQuestionsCollection(stateToUpdate);
    setIsAnswered(false);
    if (customisations.optionType === 'checkbox') {
      const checkboxChecked = questionsCollection[currentQuestion].options
        .map(
          (_item: any, index: number) =>
            (document.getElementById(`checkbox-${currentQuestion}-${index}`) as HTMLInputElement)
              ?.checked,
        )
        .some((item: boolean) => item === true);
      setIsAnswered(!checkboxChecked);
    }
  };

  const validateAnswer = () => {
    let stateToUpdate = [...questionsCollection];
    stateToUpdate[currentQuestion].checked = true;
    if (customisations.optionType === 'radio') {
      stateToUpdate[currentQuestion].options.forEach((value: any) => {
        value.selected = false;
      });
      stateToUpdate[currentQuestion].options[selectedOptionIndex].selected = true;
    } else if (customisations.optionType === 'checkbox') {
      stateToUpdate[currentQuestion].options.forEach((value: any) => {
        value.selected = false;
      });
      stateToUpdate[currentQuestion].options.forEach(
        (item: { selected: boolean }, index: number) => {
          if (
            (document.getElementById(`checkbox-${currentQuestion}-${index}`) as HTMLInputElement)
              ?.checked
          ) {
            item.selected = true;
          }
        },
      );
    }
    setQuestionsCollection(stateToUpdate);
    (document.activeElement as HTMLElement).blur();
    if (!checkAnswer) return;
    setCheckAnswer(false);
    if (currentQuestion + 1 === questionsCollection.length) {
      setIsGetResult(true);
    } else {
      setShowNext(true);
    }
  };

  return (
    <div className='m-1'>
      {isTryAgain && (
        <>
          <ShowScoreComponent percentage={score} />
          {customisations.retry && (
            <div className='tryAgainContainer py-2'>
              <Button
                id={getActionButtonText()}
                className={`${btnType} mt-1 d-flex`}
                onClick={getActionButtonFunction}
                style={mouseover ? buttonUnhover : buttonstyle}
                onMouseEnter={(event) => {
                  if (!mouseover) return;
                  const customValue = {
                    btnBgColor: customisations.buttonBgColor,
                    btnFontColor: customisations.buttonFontColor,
                  };
                  hoverOn(event.target as HTMLElement, customValue);
                }}
                onMouseLeave={(event) => {
                  if (!mouseover) return;
                  hoverOff(event.target as HTMLElement);
                }}
              >
                {getActionButtonText()}
                <i className='pt-1 ps-1 fas fa-redo' />
              </Button>
            </div>
          )}
        </>
      )}
      {!isTryAgain && (
        <section
          className='quiz fade-in px-0'
          aria-live='polite'
          style={{ background: bgColor, padding: '15px' }}
        >
          <ProgressBar
            currentQuestion={currentQuestion}
            questionLength={questionsCollection?.length}
            pbBgColor={pbBgColor}
            pbFontColor={pbFontColor}
            customisation={customisations}
          />
          <div style={borderStyle}>
            <div className='question-container'>
              <div
                className='question'
                role='button'
                tabIndex={0}
                aria-live='polite'
                style={{
                  fontSize: `${customisations.quFontSize}px `,
                  fontWeight: `${customisations.quFontWeight}`,
                }}
              >
                {ReactHtmlParser(questionsCollection[currentQuestion]?.question)}
              </div>
              <div>
                {questionsCollection[currentQuestion]?.options?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={
                        'option' +
                        (questionsCollection[currentQuestion]?.checked && !item.correct
                          ? ` dim ${
                              questionsCollection[currentQuestion]?.options[index].selected &&
                              'wrong'
                            }`
                          : '') +
                        (questionsCollection[currentQuestion]?.checked && item.correct
                          ? ` correct ${
                              questionsCollection[currentQuestion]?.options[index].selected &&
                              'correct-option'
                            }`
                          : '')
                      }
                      style={{ background: opBgColor }}
                    >
                      <label
                        className='w-100 cursor-pointer'
                        htmlFor={`${customisations.optionType}-${currentQuestion}-${index}`}
                      >
                        <Form.Check
                          type={customisations.optionType}
                          className={
                            !questionsCollection[currentQuestion]?.checked
                              ? 'd-flex align-items-center'
                              : ''
                          }
                        >
                          <Form.Check.Input
                            tabIndex={0}
                            id={`${customisations.optionType}-${currentQuestion}-${index}`}
                            className={`mt-custom formCheckInputOption ${
                              questionsCollection[currentQuestion]?.checked && 'd-none'
                            } ${customisations.optionType === 'radio' && 'custom-input'}`}
                            type={customisations.optionType}
                            name={`question-${currentQuestion}`}
                            defaultValue={item.correct}
                            checked={item.selected}
                            onChange={(event) => updateAnswer(event, index)}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                event.preventDefault();
                                updateAnswer(event, index);
                              }
                            }}
                          />
                          <Form.Check.Label
                            style={{
                              fontSize: `${customisations.opFontSize}px `,
                              fontWeight: `${customisations.opFontWeight}`,
                            }}
                            tabIndex={0}
                            aria-live='polite'
                            htmlFor={`${customisations.optionType}-${currentQuestion}-${index}`}
                          >
                            {ReactHtmlParser(item.option)}
                          </Form.Check.Label>
                          {item.showFeedback === true &&
                            questionsCollection[currentQuestion]?.checked &&
                            questionsCollection[currentQuestion]?.options[index].selected && (
                              <p className='custom-style'> {item.myfeedback}</p>
                            )}
                        </Form.Check>
                      </label>
                      {item.check === true && <McqTipComponent tip={item.mytip} />}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`text-${alignment} mb-3`}>
              {(checkAnswer || showNext || isGetResult) && (
                <Button
                  id={getActionButtonText()}
                  className={`${btnType} mt-3`}
                  onClick={getActionButtonFunction}
                  style={mouseover ? buttonUnhover : buttonstyle}
                  disabled={isAnswered}
                  onMouseEnter={(event) => {
                    if (!mouseover) return;
                    const customValue = {
                      btnBgColor: customisations.buttonBgColor,
                      btnFontColor: customisations.buttonFontColor,
                    };
                    hoverOn(event.target as HTMLElement, customValue);
                  }}
                  onMouseLeave={(event) => {
                    if (!mouseover) return;
                    hoverOff(event.target as HTMLElement);
                  }}
                >
                  {getActionButtonText()}
                  {showNext && <i className='fa fa-arrow-right ps-2 pt-1' />}
                </Button>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default MCQComponent;
