/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ToastManager } from '@crystaldelta/loree-ui-components';
import FixedDropzone from './fixedView';
import Carousel from './customOverlayView';
import { Button } from 'react-bootstrap';
import {
  getBtnHoverStyle,
  getBtnStyle,
  getDragAreaHoverStyle,
  getDragAreaStyle,
  hoverOff,
  hoverOn,
  insertIcon,
} from './dndEvents';
import ShowScoreComponent from '../../layouts/scoreProgressBar/showScoreLayout';
import ReactHtmlParser from 'react-html-parser';
import { shuffleArrayValues } from '../../../middleware/utils';
import '../../../assets/scss/dragAndDrop.scss';

const DragAndDropComponent = (props: any) => {
  const { customisations, parameters } = props;
  const [imageCollection, setImageCollection] = useState<Array<Object>>([]);
  const [dragTextCollection, setDragTextCollection] = useState<Array<string>>([]);
  const [isGetResult, setIsGetResult] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isTryAgain, setIsTryAgain] = useState(false);
  const [checkAnswer, setCheckAnswer] = useState(true);
  const [showNext, setShowNext] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [score, setScore] = useState(0);
  const alignment = customisations.alignment ? customisations.alignment : 'left';
  const mouseover = customisations.dragAreaType === 'btn btn-outline-dark' ? true : false;
  const dragAreaStyle = getDragAreaStyle(customisations);
  const dragAreaUnhover = getDragAreaHoverStyle(customisations);
  const btnHover = customisations.btnType === 'btn btn-outline-dark' ? true : false;
  const btnStyle = getBtnStyle(customisations);
  const btnUnHoverStyle = getBtnHoverStyle(customisations);
  const btnType = customisations.btnType ? customisations.btnType : 'btn btn-square btn-dark';

  useEffect(() => {
    const { dndLayout } = customisations;
    let textCollection: Array<string> = [];
    let imageCollection: Array<Object> = [];
    if (dndLayout === 'CustomOverlay') return;
    parameters.map((content: { title: string; text: string }) => {
      if (dndLayout === 'Overlay') {
        let data = ReactHtmlParser(content.text);
        data.map((children) => {
          let image = children.props.children;
          image.map((source: { props: { src: string } }) => {
            let finalValue = { title: content.title, src: source.props.src };
            imageCollection.push(finalValue);
            return null;
          });
          return null;
        });
      }
      textCollection.push(content.title);
      return null;
    });
    textCollection = shuffleArrayValues(textCollection);
    setDragTextCollection([...textCollection]);
    setImageCollection([...imageCollection]);
  }, [parameters, customisations]);

  const getActionButtonText = () => {
    switch (true) {
      case checkAnswer:
        return 'Check Answer';
      case showNext:
        return 'Next';
      case isGetResult:
        return 'Get Results';
      case isTryAgain:
        return 'Try Again';
      default:
        return '';
    }
  };

  const getActionButtonFunction = () => {
    switch (true) {
      case checkAnswer:
        return validateAnswers();
      case showNext:
        return handleNext();
      case isGetResult:
        return getResults();
      case isTryAgain:
        return tryAgain();
      default:
        return null;
    }
  };

  const getResults = () => {
    setIsGetResult(false);
    setIsTryAgain(true);
  };

  const tryAgain = () => {
    setIsTryAgain(false);
    setCheckAnswer(true);
    setScore(0);
    setActiveIndex(0);
  };

  const handleNext = () => {
    const element = document.getElementById('dragdrop');
    const droptarget = document.getElementById('buttonsHolder');
    element?.removeAttribute('style');
    droptarget?.removeAttribute('style');
    setActiveIndex(activeIndex + 1);
    setShowNext(false);
    setCheckAnswer(true);
  };

  const validateAnswers = () => {
    const { dndLayout } = customisations;
    const params = dndLayout === 'CustomOverlay' ? parameters[activeIndex].dropZone : parameters;
    let dropValues = [];
    params.map((element: { title: string; data: { text: string } }) => {
      const title = element.title ? element.title : element.data.text;
      const component = document.getElementById(title);
      if (!component) return;
      const innerText = component.textContent;
      if (innerText === '') {
        setIsAnswered(true);
        component.style.border = '3px dotted #9dd8bb';
      } else dropValues.push(innerText);
      return null;
    });
    if (dropValues.length !== params.length) return;
    checkCorrectAnswer();
  };

  const checkCorrectAnswer = () => {
    const element = document.getElementById('dragdrop');
    if (element) {
      element.style.pointerEvents = 'none';
    }
    const droptarget = document.getElementById('buttonsHolder');
    if (droptarget) {
      droptarget.style.border = 'none';
    }
    customisations.dndLayout === 'CustomOverlay'
      ? calculateScoreForCustomDND()
      : calculateScoreForFixedDND();
  };

  const calculateScoreForFixedDND = () => {
    const correctAnswers: Array<string> = [];
    const { dndLayout } = customisations;
    parameters.map((data: { title: string }) => {
      const component = document.getElementById(data.title);
      const correct = insertIcon(component, dndLayout);
      if (correct) correctAnswers.push(correct);
      return correctAnswers;
    });
    setScore(parseInt(((correctAnswers.length / parameters.length) * 100).toFixed()));
    setCheckAnswer(false);
    setIsGetResult(true);
  };

  const calculateScoreForCustomDND = () => {
    const { dndLayout } = customisations;
    const dragArea = parameters[activeIndex].dropZone;
    const correctAnswers: Array<string> = [];
    dragArea.map((element: { data: { text: string } }) => {
      const component = document.getElementById(element.data.text);
      const correct = insertIcon(component, dndLayout);
      if (correct) correctAnswers.push(correct);
      return correctAnswers;
    });
    let currentScore = ((correctAnswers.length / dragArea.length) * 100).toFixed();
    setCheckAnswer(false);
    if (activeIndex + 1 === parameters.length) {
      setScore(parseInt(((score + parseInt(currentScore)) / parameters.length).toFixed()));
      setIsGetResult(true);
    } else {
      setScore(score + parseInt(currentScore));
      setShowNext(true);
    }
  };

  return (
    <>
      {isAnswered && (
        <ToastManager
          toastType='info'
          toastMessage='Please fill all answers'
          closeButton
          closeToast={() => setIsAnswered(false)}
        />
      )}
      {isTryAgain && <ShowScoreComponent percentage={score} />}
      {customisations.dndLayout !== 'CustomOverlay' && !isTryAgain && (
        <FixedDropzone
          data={parameters}
          image={imageCollection}
          dragText={dragTextCollection}
          customisations={{
            customisation: customisations,
            dragAreaStyle: dragAreaStyle,
            dragAreaUnhover: dragAreaUnhover,
            mouseover: mouseover,
            dropZoneBgColor: customisations.dropzoneBgColor,
            dropZoneOpacity: customisations.dropzoneOpacity,
          }}
        />
      )}
      {customisations.dndLayout === 'CustomOverlay' && !isTryAgain && (
        <div id='dragdrop' className='text-center'>
          <Carousel
            idx={activeIndex}
            data={parameters}
            customValue={customisations}
            customisations={{
              customisation: customisations,
              dragAreaStyle: dragAreaStyle,
              dragAreaUnhover: dragAreaUnhover,
              mouseover: mouseover,
              dropZoneBgColor: customisations.dropzoneBgColor,
              dropZoneOpacity: customisations.dropzoneOpacity,
            }}
          />
        </div>
      )}
      <div className={!isTryAgain ? `text-${alignment}` : 'tryAgainContainer py-2'}>
        {(checkAnswer || showNext || isGetResult || isTryAgain) && (
          <Button
            id={getActionButtonText()}
            className={`${btnType} ${isTryAgain ? 'd-flex  mt-1' : 'mt-5'}`}
            onClick={getActionButtonFunction}
            style={btnHover ? btnUnHoverStyle : btnStyle}
            onMouseEnter={(event) => {
              if (!btnHover) return;
              hoverOn(event.target as HTMLElement, customisations);
            }}
            onMouseLeave={(event) => {
              if (!btnHover) return;
              hoverOff(event.target as HTMLElement);
            }}
          >
            {getActionButtonText()}
            {isTryAgain && <i className='pt-1 ps-1 fas fa-redo' />}
            {showNext && <i className='fa fa-arrow-right ps-2 pt-1' />}
          </Button>
        )}
      </div>
    </>
  );
};

export default DragAndDropComponent;
